import React from "react";

import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import "../style/Inicio.css";

class Inicio extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            email: "",
            password: "",
            method: "login"
        }
    }

    handleSubmit() {
        const URL = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:3000/api/' : 'https://pd.davi.cl/api/';
        if (this.state.method === "login") {
            fetch(URL + "login", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    username: this.state.username,
                    email: this.state.email,
                    password: this.state.password
                })
            })
                .then(response => {
                    return response.json();
                })
                .then(resp => {
                    if (resp.status === "success") {
                        localStorage.setItem("token", resp.data.token);
                        toast.success("Usuario logueado con éxito.");
                        this.props.onLogin();
                        return;
                    } else if (resp.status === "error") {
                        toast.error(resp.message);
                        return;
                    } else {
                        toast.error(resp.message);
                        return;
                    }
                });
        } else {
            fetch(URL + "register", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    username: this.state.username,
                    email: this.state.email,
                    password: this.state.password
                })
            })
                .then(response => {
                    return response.json();
                })
                .then(resp => {
                    if (resp.status === "success") {
                        localStorage.setItem("token", resp.data.token);
                        toast.success("Usuario registrado con éxito.");
                        this.props.onLogin();
                        return
                    } else if (resp.status === "error") {
                        toast.error(resp.message);
                        return;
                    } else {
                        toast.error(resp.message);
                        return;
                    }
                });
        }
    }

    setUsername(username) {
        this.setState({ username: username });
    }

    setEmail(email) {
        this.setState({ email: email });
    }

    setPassword(password) {
        this.setState({ password: password });
    }

    canLogin() {
        return this.state.username.length > 0 && this.state.password.length > 0;
    }

    render() {
        return (
            <div className="login-container">
                <div className="logo">
                    <img src="https://davi.cl/wp-content/uploads/2023/03/LOGO-WEB-LIGHT-02.png" alt="logo" />
                </div>
                <p className="login-header">{this.state.method === "login" ? "Iniciar sesión" : "Registrarse"}</p>
                <div className="auth-form">
                    <form>
                        <label>Usuario</label>
                        <input
                            type="text"
                            onChange={(event) => this.setUsername(event.target.value)}
                        />
                        <label>Correo electrónico</label>
                        <input
                            type="email"
                            onChange={(event) => this.setEmail(event.target.value)}
                        />
                        <label>Contraseña</label>
                        <input
                            type="password"
                            onChange={(event) => this.setPassword(event.target.value)}
                        />
                        <button type="button" disabled={!this.canLogin()} onClick={() => this.handleSubmit()}> {this.state.method === "login" ? "Iniciar sesión" : "Registrarse"} </button>
                    </form>
                </div>
                <p className="block" style={{ display: "none" }}>
                    {this.state.method === "login" ? "¿Aún no tienes una cuenta?" : "¿Ya tienes una cuenta?"}
                    <a onClick={() => this.setState({ method: this.state.method === "login" ? "register" : "login" })} href="#">
                        {this.state.method === "login" ? "Regístrate" : ""}
                    </a>
                </p>
            </div>
        );
    }
}

export default Inicio;