import Joi from 'joi';

const PreciosJuridicoNatural = Joi.object({
    juridico: Joi.number().required(),
    natural: Joi.number().required(),
});

const Precios = Joi.object({
    _50: PreciosJuridicoNatural.required(),
    _40: PreciosJuridicoNatural.required(),
})

const ProductVO = Joi.object({
    codigo: Joi.number().required(),
    descripcion: Joi.string().required().allow(""),
    precio_catalogo: Joi.number().required(),
    precios: Precios.required(),
    puntos: Joi.number().precision(2).required(),
    en_promocion: Joi.boolean().required(),
})

const ProductCatalogo = Joi.object({
    codigo: Joi.number().required(),
    codigo_catalogo: Joi.number().required(),
    descripcion: Joi.string().required(),
    nombre: Joi.string().required(),
    precio: Joi.number().required(),
})

const Product = Joi.object({
    codigo: Joi.number().required(),
    descripcion: Joi.string().required(),
    disponible: Joi.boolean().required(),

    precio_catalogo: Joi.number().optional(),
    precios: Precios.optional(),
    puntos: Joi.number().optional(),
    en_promocion: Joi.boolean().optional(),
})

export { Product, ProductVO, ProductCatalogo };