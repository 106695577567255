import React from "react";

import Products from "./Products";
import Inicio from "./Inicio";

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogged: false,
            validated: false
        }
    }

    componentDidMount() {
        // TODO: se llama más de una vez
        const token = localStorage.getItem("token");

        if (!token || this.state.validated) {
            return;
        }

        const URL = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:3000/api/validate-token' : 'https://pd.davi.cl/api/validate-token';

        fetch(URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: token,
            })
        })
            .then(response => {
                if (response.status === 200) {
                    this.setState({ isLogged: true, validated: true });
                } else if (response.status === 401) {
                    this.setState({ isLogged: false, validated: true });
                }
            });
    }

    setLogged(isLogged) {
        if (!isLogged) {
            localStorage.removeItem("token");
        }
        this.setState({ isLogged: isLogged });
    }

    render() {
        return (
            <div className="main-container">
                {this.state.isLogged ? <Products logout={() => this.setLogged(false)} token={localStorage.getItem("token")} /> : <Inicio onLogin={() => this.setLogged(true)} />}
            </div>
        );
    }
}

export default Main;