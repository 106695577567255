import '../style/Products.css';

import React from 'react';
import { FaSortNumericDown, FaSortNumericUp, FaSortAlphaDown, FaSortAlphaUp } from 'react-icons/fa';

import { Product, ProductVO, ProductCatalogo } from '../models/Products';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Products extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      __id: 0,

      productosDisponibles: [],
      todosProductos: [],
      ultimaActualizacion: null,

      productos: [],
      filtros: {
        codigoMenorMayor: true,
        nombreAZ: false
      }
    }
  }

  /**
   * Actualiza `this.state.productosDisponibles`, `this.state.ultimaActualizacion` y `this.state.todosProductos`
   * con los datos de los archivos JSON.
   * @returns {void}
   */
  actualizarCache() {

    const url = process.env.NODE_ENV === 'development' ? 'http://localhost:3000/api/productos-vo' : 'https://pd.davi.cl/api/productos-vo';
    const url2 = process.env.NODE_ENV === 'development' ? 'http://localhost:3000/api/productos' : 'https://pd.davi.cl/api/productos';

    if (this.state.productosDisponibles.length > 0) {
      return;
    } else if (this.state.todosProductos.length > 0) {
      return;
    }

    fetch(url, {
      method: "GET",
      headers: {
        "Authorization": "Bearer " + this.props.token
      }
    })
      .then(response => response.json())
      .then(data => {
        let dateParts = data.ultima_actualizacion.split("T");
        let date = new Date(dateParts[0] + "T" + dateParts[1]);
        let productos = []

        if (data.productos.length > 0) {
          productos = data.productos.map(producto => {
            let { error, value } = ProductVO.validate(producto);
            if (error) {
              console.error(error);
              return null;
            }
            return value;
          });
        }

        this.setState({ productosDisponibles: productos, ultimaActualizacion: date })

      })
      .catch(error => {
        console.error('Error al obtener productos:', error)
      });

    fetch(url2, {
      method: "GET",
      headers: {
        "Authorization": "Bearer " + this.props.token
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.length > 0) {

          let productos = data.map(producto => {
            let { error, value } = ProductCatalogo.validate(producto);
            if (error) {
              console.error(error);
              return null;
            }
            return value;
          });

          this.setState({ todosProductos: productos })
        }
      })
      .catch(error => {
        console.error('Error al obtener productos:', error)
      })
  }

  setState(state, callback) {
    if (state.productos) {
      state.productos = state.productos.map(producto => {
        let { error, value } = Product.validate(producto);
        if (error) {
          console.error(error);
          return null;
        }
        return value;
      });
    }
    super.setState(state, callback);
  }

  /**
   * Chequea si el producto enviado como parámetro está disponible en la Oficina Virtual.
   * @param {*} producto 
   * @returns {boolean}
   */
  productoDisponible(producto) {
    for (var i = 0; i < this.state.productosDisponibles.length; i++) {
      if (this.state.productosDisponibles[i].codigo === producto.codigo) {
        return true;
      }
    }
    return false;
  }

  /**
   * Obtiene el producto de la lista de productos disponibles en la Oficina Virtual.
   * @param {*} producto
   * @returns {Optional<Producto>}
   */
  obtenerProductoCache(producto) {
    for (var i = 0; i < this.state.productosDisponibles.length; i++) {
      if (this.state.productosDisponibles[i].codigo === producto.codigo) {
        return this.state.productosDisponibles[i];
      }
    }
    return null;
  }

  /**
   * Ordena los productos por código de producto.
   * @param {boolean} render - Si es `true`, renderiza el componente. Si es `false`, devuelve el array ordenado.
   * @param {boolean} menorMayor - Si es `true`, ordena de menor a mayor. Si es `false`, ordena de mayor a menor.
   * @param {Optional<object>} productos - Si se envía un array de productos, ordena ese array. Si no, ordena `this.state.productos`.
   * @returns {void|object}
   */
  ordenarPorCodigo(render = true, menorMayor = true, productos = null) {
    let nuevoOrden = productos ? productos : this.state.productos;
    nuevoOrden.sort((a, b) => {
      if (a.codigo > b.codigo) {
        return menorMayor ? 1 : -1;
      }
      if (a.codigo < b.codigo) {
        return menorMayor ? -1 : 1;
      }
      return 0;
    });
    if (render) {
      let filtros = this.state.filtros;
      filtros.codigoMenorMayor = menorMayor;
      this.setState({ productos: nuevoOrden, filtros: filtros });
      return;
    }
    return nuevoOrden;
  }

  /**
   * Ordena los productos por nombre de producto.
   * @param {boolean} render - Si es `true`, renderiza el componente. Si es `false`, devuelve el array ordenado.
   * @param {boolean} AZ - Si es `true`, ordena de A a Z. Si es `false`, ordena de Z a A.
   * @param {Optional<object>} productos - Si se envía un array de productos, ordena ese array. Si no, ordena `this.state.productos`.
   * @returns {void|object}
   */
  ordenarAZ(render = true, AZ = false, productos = null) {
    let nuevoOrden = productos ? productos : this.state.productos;
    nuevoOrden.sort((a, b) => {
      if (a.descripcion > b.descripcion) {
        return AZ ? 1 : -1;
      }
      if (a.descripcion < b.descripcion) {
        return AZ ? -1 : 1;
      }
      return 0;
    }
    );
    if (render) {
      let filtros = this.state.filtros;
      filtros.nombreAZ = AZ;
      this.setState({ productos: nuevoOrden, filtros: filtros, __id: this.state.__id + 1 });
    }
  }

  /**
   * Maneja el evento de cambio de disponibilidad del select.
   * @param {object} event - Evento de cambio de disponibilidad.
   * @returns {void}
   */
  handleDisponibleChange(event) {
    let nuevoorden = [];
    let productos = this.productosConDisponible();

    if (event.target.value === "todos") {
      productos = this.quitarDuplicados(productos);
      productos = this.ordenarPorCodigo(false, true, productos);
      this.setState({ productos: productos, __id: this.state.__id + 1 });
      return;
    }

    for (var i = 0; i < productos.length; i++) {
      let producto = productos[i];
      if (event.target.value === "disponible") {
        if (producto.disponible) {
          nuevoorden.push(producto);
        }
      } else if (event.target.value === "no_disponible") {
        if (!producto.disponible) {
          nuevoorden.push(producto);
        }
      }
    }

    this.setState({ productos: this.quitarDuplicados(nuevoorden), __id: this.state.__id + 1 });
  }

  /**
   * Chequea si el producto enviado como parámetro está en la lista de productos enviada como parámetro.
   * @param {*} producto
   * @param {object} lista
   * @returns {boolean}
   */
  estaEnLista(producto, lista) {
    for (var i = 0; i < lista.length; i++) {
      if (lista[i].codigo === producto.codigo) {
        return true;
      }
    }
    return false;
  }

  /**
   * Quita los productos duplicados de la lista de productos enviada como parámetro.
   * @param {object} productos
   * @returns {object}
   */
  quitarDuplicados(productos) {
    let productosSinDuplicados = [];
    for (var i = 0; i < productos.length; i++) {
      let producto = productos[i];
      if (!this.estaEnLista(producto, productosSinDuplicados)) {
        productosSinDuplicados.push(producto);
      }
    }
    return productosSinDuplicados;
  }

  /**
   * Devuelve un array de productos con la propiedad `disponible` seteada.
   * @returns {object}
   */
  productosConDisponible() {
    let productos = [];
    for (var i = 0; i < this.state.todosProductos.length; i++) {
      let producto = this.obtenerProductoCache(this.state.todosProductos[i]);
      if (producto === null) {
        producto = {
          codigo: this.state.todosProductos[i].codigo,
          descripcion: this.state.todosProductos[i].nombre,
          disponible: false
        }
      } else {
        producto.disponible = this.productoDisponible(producto);
      }
      productos.push(producto);
    }
    return productos;
  }

  /**
   * Actualiza los productos del componente.
   * @returns {void}
   */
  actualizarProductos() {
    let productos = this.productosConDisponible();
    this.state.productos = this.quitarDuplicados(productos);
  }

  render() {
    this.actualizarCache();

    if (this.state.productos.length === 0 && this.state.productosDisponibles.length > 0) {
      this.actualizarProductos();
    }

    if (this.state.__id === 0) {
      this.ordenarPorCodigo(false, this.state.filtros.codigoMenorMayor);
    }

    return (
      <div className="main-container" >
        <p><strong>Productos disponibles en la Oficina Virtual</strong></p>
        <button onClick={() => { toast.success("Sesión cerrada con éxito."); this.props.logout() }}>Cerrar sesión</button>
        <p style={{ fontSize: '90%' }}>Ultima Actualización: {this.state.ultimaActualizacion ? this.state.ultimaActualizacion.toLocaleString() : ''}</p>
        <div className="product-table" key={this.state.id}>
          <table>
            <thead>
              <tr>
                <th onClick={(_) => this.ordenarPorCodigo(true, !this.state.filtros.codigoMenorMayor)}>ID del Producto {this.state.filtros.codigoMenorMayor ? <FaSortNumericDown /> : <FaSortNumericUp />}</th>
                <th onClick={(_) => this.ordenarAZ(true, !this.state.filtros.nombreAZ)}>Nombre del Producto {this.state.filtros.nombreAZ ? <FaSortAlphaUp /> : <FaSortAlphaDown />}</th>
                <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#751cec' }} id="filtro-disponibilidad">
                  <th>
                    ¿Está disponible en la Oficina Virtual?<br />
                    Filtra por disponibilidad: <select onChange={(_) => this.handleDisponibleChange(_)}>
                      <option value="todos">Todos</option>
                      <option value="disponible">Disponible</option>
                      <option value="no_disponible">No disponible</option>
                    </select>
                  </th>
                </div>
              </tr>
            </thead>
            <tbody>
              {this.state.productos.map((producto) => {
                return (
                  <tr key={producto.codigo}>
                    <td>{producto.codigo}</td>
                    <td>{producto.descripcion}</td>
                    <td>{producto.disponible ? 'Disponible' : 'No disponible'}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

      </div >
    )
  };
}

export default Products;
